import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { get, first } from 'lodash'
import Layout from '../components/layout'
import HeroImage from '../components/heroImage'
import { getDataForLanguage, fixCompareTwoArraysForImages } from '../utils/getDataForLanguage'

export class ResultsPage extends Component {
    render() {
        const allPageData = get(this, "props.data.allContentfulResultsPage.edges", "");

        let pageData = first(getDataForLanguage(
            allPageData.map(item => item.node),
            process.env.COUNTRY
        ))

        let heroImageAndTextData = allPageData.map(item =>
            get(item, "node.heroImageAndText", item)
        )
        let transHeroImageAndTextData = first(
            getDataForLanguage(heroImageAndTextData, process.env.COUNTRY)
        )
        
        const resultsArrays = allPageData.map(item => item.node.results);
        let resultsDataWithImages = fixCompareTwoArraysForImages(resultsArrays[1], resultsArrays[0]);

        

        return (
            <Layout
                title={get(pageData, 'metaTitle','')} 
                description={get(pageData, 'metaDescription','')}
                image={get(pageData, 'heroImageAndText.backgroundImage.file.url', '')}
                pathName="/results"
            >
                <HeroImage entity={transHeroImageAndTextData} />

                <div className="results-page">
                    <div className="container" id="maincontent">
                        <div className="results row flex-row">
                            {resultsDataWithImages.map((entry, key) => {
                                // if (entry.image !== null) {
                                //     return (
                                //         <div key={key} className="result-box result-box--with-image w-50">
                                //             <div className="result-box__content result-box--with-image__content">
                                //                 <div className="result-box__text">{entry.text.text}</div>
                                //             </div>
                                //             {entry.image !== null &&
                                //                 <img src={get(entry, 'image.file.url', '')} alt={get(entry, 'image.title', '')} />
                                //             }
                                //         </div>
                                //     )
                                // }
                                return (
                                    <div key={key} className="result-box result-box--without-image w-50">
                                        <div className="result-box__content">
                                            <div className="result-box__text">{entry.text.text}</div>
                                            <div className="result-box__author">- {entry.author}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default ResultsPage

export const pageQuery = graphql`
  query ResultsPageQuery {
    allContentfulResultsPage {
      edges {
        node {
          metaTitle
          metaDescription
          heroImageAndText {
            title
            linkPath
            linkText
            description
            backgroundImage {
              file {
                url
              }
              title
              node_locale
              contentful_id
            }
            node_locale
            contentful_id
          }
          results {
            text {
              text
            }
            author
            node_locale
            contentful_id
          }
          node_locale
        }
      }
    }
  }
`